<template>
  <en-drawer :model-value="modelValue" title="开票信息上传" @close="$emit('update:model-value', false)">
    <en-form>
      <en-form-item label="发票类型">
        <en-input :model-value="form.data.invoiceType?.message" disabled></en-input>
      </en-form-item>
      <en-form-item label="发票抬头">
        <en-input :model-value="form.data.invoiceTitle" disabled></en-input>
      </en-form-item>
      <en-form-item label="税号">
        <en-input :model-value="form.data.invoiceTaxNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="开户行">
        <en-input :model-value="form.data.invoiceBankName" disabled></en-input>
      </en-form-item>
      <en-form-item label="开户行账号">
        <en-input :model-value="form.data.invoiceBankAccount" disabled></en-input>
      </en-form-item>
      <en-form-item label="注册地址">
        <en-input :model-value="form.data.invoiceAddress" disabled></en-input>
      </en-form-item>
      <en-form-item label="注册电话">
        <en-input :model-value="form.data.invoicePhoneNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="是否开票">
        <select-maintain
          v-model="form.data.invoiceSent"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'F'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="开票单号">
        <en-input v-model="form.data.invoiceNo" :disabled="form.data.status?.code === 'F'"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click" :disabled="form.data.status?.code === 'F'">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudMallDefinitions['MallComboOrderDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.init()
          this.form.data.id = this.data?.id
          this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo/order/:mallComboOrderId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          update: {
            action: 'PUT /enocloud/mall/combo/order',
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form.update()
              this.footer.cancel.click()
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
